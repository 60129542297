import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    inject,
    input, isDevMode,
    ViewEncapsulation
} from '@angular/core';
import { ISbComponentType } from 'storyblok-js-client';
import { NgComponentOutlet } from '@angular/common';
import { StoryblokService } from '@seven1/angular-storyblok/api';

@Component({
    selector: 's1-cms-component',
    imports: [NgComponentOutlet],
    templateUrl: './cms.component.html',
    styleUrl: './cms.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsComponent {
    private _elRef = inject(ElementRef);
    private _service = inject(StoryblokService);
    blok = input.required<ISbComponentType<string>>();
    extraInputs = input<Record<string, unknown>>();

    constructor() {
        effect(() => {
            const editable = this.blok()._editable;
            if (editable) {
                this._service.setElementEditable(
                    this._elRef.nativeElement,
                    editable
                );
            }
        });
    }

    componentType = computed(() => {
        const component = this.blok()?.component;
        if (component) {
            try {
                return this._service.resolveComponent(component);
            } catch (e) {
                if (isDevMode()) console.error(e);
                return null;
            }
        }
        return null;
    });
    inputs = computed(() => {
        return this._createInputs(this.blok(), this.extraInputs());
    });

    private _blokToInputs(blok: ISbComponentType<string>): Record<string, unknown> {
        const inputs = { ...blok };
        delete inputs['component'];
        delete inputs['_uid'];
        delete inputs['_editable'];
        return inputs;
    }

    private _createInputs(
        blok: ISbComponentType<string>,
        extraInputs?: Record<string, unknown>
    ): Record<string, unknown> {
        return {...extraInputs, block: this._blokToInputs(blok)};
    }
}
