import {
    EnvironmentProviders,
    InjectionToken,
    makeEnvironmentProviders,
    Type
} from '@angular/core';
import { ISbConfig } from 'storyblok-js-client';
import { StoryblokRichTextOptions } from '@storyblok/richtext';
import { GtmConfig } from '@seven1/angular/gtm';
import { KameleoonConfig } from '@seven1/angular/kameleoon';
import { UsercentricsConfig } from '@seven1/angular/usercentrics';

export interface CmsComponents<C = unknown> {
    [key: string]: Type<C>;
}


/**
 * The storyblok config (included token, if not default an endpoint and some more storyblok settings)
 * */
export interface StoryblokConfig extends ISbConfig {
    /** storyblok token with it's permissions */
    accessToken: string;
    /** story feetch base path (if no base folder - `'/'`, else the name of the base folder like `"/folder-name"`) */
    storyBasePath: string;
    /** slug for the layout story */
    layoutStorySlug: string;
    /** slug for the redirect routes */
    redirectsStorySlug?: string;
    /** slug for the blog (overview page) */
    blogSlug: string;
    /** blog article content type key */
    blogArticleContentTypeKey: string;
    /** configured components to use in storyblok */
    components: CmsComponents;
    /** endpoint url, only set if not a default region depending api endpoint */
    endpoint?: string;
    /** if https should be used (only use http in development) */
    https?: boolean;
    /** the region, you use your storyblok in (if non is selected, `eu` is default) */
    region?: 'eu' | 'us' | 'cn' | 'ap' | 'ca';
    /** version of the fetched data (published or draft) - be aware, that your token  has to allow draft versions */
    version?: 'draft' | 'published';
    /** rich text resolver configuration */
    richText?: StoryblokRichTextOptions<string>;
}

export interface IStoryblokEnvironment {
    cms: StoryblokConfig;
    google_tag_manager: GtmConfig;
    usercentrics: UsercentricsConfig;
    kameleoon: KameleoonConfig;
}

export const STORYBLOK_CONFIG = new InjectionToken<StoryblokConfig>(
    'Storyblok config',
    {
        factory: () => ({
            components: {},
            accessToken: '',
            storyBasePath: '/',
            layoutStorySlug: '/layout',
            blogArticleContentTypeKey: 'BlogArticlePage',
            blogSlug: 'blog',
        })
    }
);

export function provideStoryblok(
    config: StoryblokConfig,
): EnvironmentProviders {
    return makeEnvironmentProviders([
        { provide: STORYBLOK_CONFIG, useValue: config },
    ]);
}
